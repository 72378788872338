import React from "react";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { ESTADOS } from "../../../utils/constants";
import { toast } from "react-toastify";

const Association = ({ data, onChange, nextStep }) => {
  const onSubmit = () => {
    if (
      !data.productor.trim() ||
      !data.fermentador.trim() ||
      !data.dirección.trim() ||
      !data.ciudad.trim() ||
      !data.email.trim() ||
      !data.telefono.trim()
    ) {
      toast.error("Por favor llene todos los campos");
      return;
    }

    nextStep();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Nombre del productor*"
          value={data.productor}
          onChange={(e) => onChange({ ...data, productor: e.target.value })}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Nombre del fermentador*"
          value={data.fermentador}
          onChange={(e) => onChange({ ...data, fermentador: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Dirección*"
          value={data.dirección}
          onChange={(e) => onChange({ ...data, dirección: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          select
          fullWidth
          variant="standard"
          label="¿De qué estado participa?*"
          value={data.dirección}
          onChange={(e) => onChange({ ...data, dirección: e.target.value })}
        >
          {ESTADOS.map((estado, key) => (
            <MenuItem key={key} value={estado}>
              {estado}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="¿De qué ciudad participa?*"
          value={data.ciudad}
          onChange={(e) => onChange({ ...data, ciudad: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Correo eléctronico*"
          value={data.email}
          onChange={(e) => onChange({ ...data, email: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Número de telefono*"
          value={data.telefono}
          type="number"
          onChange={(e) => {
            const value = e.target.value;

            if (value.length > 10) return;

            const regex = /^\d+$/;
            if (regex.test(value)) {
              onChange({ ...data, telefono: value });
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button variant="contained" onClick={onSubmit}>
            Siguiente
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default Association;
