import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";

const DescripcionFinca = ({ data, onChange, nextStep, prevStep }) => {
  const [checkeds, setCheckeds] = useState({
    arbolSombraPermanente: false,
    usoFertilizantes: false,
  });

  const addItemFertilizante = () => {
    const newFertilizantes = [...data.usoFertilizantes];

    newFertilizantes.push("");
    onChange({ ...data, usoFertilizantes: newFertilizantes });
  };

  const deleteItemFertilizante = (key) => {
    const newFertilizantes = [...data.usoFertilizantes];
    newFertilizantes.splice(key, 1);

    onChange({ ...data, usoFertilizantes: newFertilizantes });
  };

  const onSubmit = () => {
    if (
      !data.size.trim() ||
      !data.arbolDensidadPorHectarea.trim() ||
      !data.productividad.trim() ||
      !data.arbolEdad.trim() ||
      !data.tipoCultivo.trim()
    ) {
      toast.error("Por favor llene todos los campos");
      return;
    }

    nextStep();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Tamañó*"
          type="number"
          value={data.size}
          onChange={(e) => {
            const value = e.target.value;

            const regex = /^\d+$/;
            if (regex.test(value)) {
              onChange({ ...data, size: value });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          type="number"
          label="Densidad de los árboles de cacao por hectárea*"
          value={data.arbolDensidadPorHectarea}
          onChange={(e) => {
            const value = e.target.value;

            const regex = /^\d+$/;
            if (regex.test(value)) {
              onChange({ ...data, arbolDensidadPorHectarea: value });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          type="number"
          label="Productividad (kg de granos de cacao seco/ha)*"
          value={data.productividad}
          onChange={(e) => {
            const value = e.target.value;

            const regex = /^\d+$/;
            if (regex.test(value)) {
              onChange({ ...data, productividad: value });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          type="number"
          label="Edad promedio de los árboles*"
          value={data.arbolEdad}
          onChange={(e) => {
            const value = e.target.value;

            const regex = /^\d+$/;
            if (regex.test(value)) {
              onChange({ ...data, arbolEdad: value });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Tipo de prácticas de cultivo*"
          value={data.tipoCultivo}
          onChange={(e) => onChange({ ...data, tipoCultivo: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Certificación"
          value={data.certificacion}
          onChange={(e) => onChange({ ...data, certificacion: e.target.value })}
        />
      </Grid>

      <Grid item xs={12} container alignItems="center">
        <Grid item>
          <Checkbox
            sx={{ mr: 1, mt: 1 }}
            checked={checkeds.arbolSombraPermanente}
            onChange={(e) =>
              setCheckeds({
                ...checkeds,
                arbolSombraPermanente: !checkeds.arbolSombraPermanente,
              })
            }
          />
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            disabled={!checkeds.arbolSombraPermanente}
            variant="standard"
            label="Arboles de sombra permanente (sí/no), ¿cuál?*"
            value={data.arbolSombraPermanente}
            onChange={(e) =>
              onChange({ ...data, arbolSombraPermanente: e.target.value })
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: "grey.50",
            padding: 2,
            borderRadius: 4,
          }}
        >
          <Grid container alignItems="center" maxHeight={300} overflow={"auto"}>
            <Grid item>
              <FormControlLabel
                label="Uso de fertilizantes, pesticidas. (sí/no), ¿cuáles?*"
                control={<Checkbox checked={checkeds.usoFertilizantes} />}
                onChange={(e) => {
                  const newValue = !checkeds.usoFertilizantes;

                  if (!newValue) {
                    onChange({ ...data, usoFertilizantes: [] });
                  }

                  setCheckeds({ ...checkeds, usoFertilizantes: newValue });
                }}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={!checkeds.usoFertilizantes}
                variant="outlined"
                size="small"
                onClick={addItemFertilizante}
              >
                Agregar
              </Button>
            </Grid>

            <Grid item xs={12}>
              {data.usoFertilizantes.map((item, key) => (
                <TextField
                  fullWidth
                  sx={{ marginTop: key === 0 ? 0 : 2 }}
                  label="Ingresa el nombre"
                  variant="standard"
                  value={item}
                  onChange={(e) => {
                    const newFertilizantes = [...data.usoFertilizantes];
                    newFertilizantes[key] = e.target.value;

                    onChange({ ...data, usoFertilizantes: newFertilizantes });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => deleteItemFertilizante(key)}>
                        <Delete />
                      </IconButton>
                    ),
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: "grey.50",
            padding: 2,
            borderRadius: 4,
          }}
        >
          <Grid container alignItems="center" maxHeight={300} overflow={"auto"}>
            <Grid item>
              <FormLabel>
                ¿Qué tipos de frutales o maderables hay en la plantación?*
              </FormLabel>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  const newFrutales = [...data.tipoFrutalesMaderables];
                  newFrutales.push("");

                  onChange({ ...data, tipoFrutalesMaderables: newFrutales });
                }}
              >
                Agregar
              </Button>
            </Grid>

            <Grid item xs={12}>
              {data.tipoFrutalesMaderables.map((item, key) => (
                <TextField
                  fullWidth
                  sx={{ marginTop: key === 0 ? 0 : 2 }}
                  label="Ingresa el nombre"
                  variant="standard"
                  value={item}
                  onChange={(e) => {
                    const newFrutales = [...data.tipoFrutalesMaderables];
                    newFrutales[key] = e.target.value;

                    onChange({ ...data, tipoFrutalesMaderables: newFrutales });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          const newFrutales = [...data.tipoFrutalesMaderables];
                          newFrutales.splice(key, 1);

                          onChange({
                            ...data,
                            tipoFrutalesMaderables: newFrutales,
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    ),
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button variant="outlined" onClick={prevStep}>
            Atrás
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            Siguiente
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default DescripcionFinca;
