import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";

const OrigenGenetico = ({ data, onChange, nextStep, prevStep }) => {
  const onSubmit = () => {
    if (
      !data.localAsignado.trim() ||
      !data.origenGenetico.trim() ||
      !data.tipoCruce.trim() ||
      !data.tecnicaPlantado.trim()
    ) {
      toast.error("Por favor llene todos los campos");
      return;
    }

    nextStep();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Nombre local o asignado*"
          value={data.localAsignado}
          onChange={(e) => onChange({ ...data, localAsignado: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Origen genético dominante*"
          value={data.origenGenetico}
          onChange={(e) =>
            onChange({ ...data, origenGenetico: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Si es un cruce, especifique (madre x padre)*"
          value={data.tipoCruce}
          onChange={(e) => onChange({ ...data, tipoCruce: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="¿El material fue plantado por semillas, injertos u otra técnica de propagación?*"
          value={data.tecnicaPlantado}
          onChange={(e) =>
            onChange({ ...data, tecnicaPlantado: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button variant="outlined" onClick={prevStep}>
            Atrás
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            Siguiente
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default OrigenGenetico;
