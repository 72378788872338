import React, { useState } from "react";
import {
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Seo from "../../assets/components/seo";
import Navbar from "../../assets/components/Navbar";
import Association from "../../assets/components/ConcursoCacaoMexicanoOrigen/Association";
import DescripcionFinca from "../../assets/components/ConcursoCacaoMexicanoOrigen/DescripcionFinca";
import OrigenGenetico from "../../assets/components/ConcursoCacaoMexicanoOrigen/OrigenGenetico";
import LoteOrigen from "../../assets/components/ConcursoCacaoMexicanoOrigen/LoteOrigen";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { toast } from "react-toastify";
import { app } from "../../utils/server/firebase";
import Footer from "../../assets/components/Footer";

const db = getFirestore(app);

const ConcursoCacaoMexicanoOrigen = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    productor: "",
    fermentador: "",
    direccion: "",
    estado: "",
    ciudad: "",
    email: "",
    telefono: "",
    size: "",
    arbolDensidadPorHectarea: "",
    productividad: "",
    arbolEdad: "",
    tipoCultivo: "",
    arbolSombraPermanente: "",
    tipoFrutalesMaderables: [],
    certificacion: "",
    usoFertilizantes: [],
    localAsignado: "",
    origenGenetico: "",
    tipoCruce: "",
    tecnicaPlantado: "",
    cantidadLote: "",
    fechaCosecha: null,
    tiempoAlmacenado: "",
  });

  const nextStep = () => setActiveStep(activeStep + 1);
  const prevStep = () => setActiveStep(activeStep - 1);

  const finishStep = async () => {
    try {
      setLoading(true);

      const payload = {
        ...data,
        fechaCosecha: data.fechaCosecha.toDate(),
        tipoFrutalesMaderables: data.tipoFrutalesMaderables.filter((x) => x),
        usoFertilizantes: data.usoFertilizantes.filter((x) => x),
        createAt: new Date(),
        updateAt: new Date(),
      };

      const docRef = collection(db, "cacaoMexicano");
      await addDoc(docRef, payload);

      toast.success("¡Gracias por participar!");
    } catch (error) {
      toast.error(
        "Hubo un problema al crear tu solicitud. Recargue e intente de nuevo."
      );
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo title="Primer Concurso de Cacao Mexicano de Origen" />
      <Navbar />

      <Grid
        container
        direction="column"
        maxWidth="md"
        margin="0 auto"
        marginY={12}
        minHeight="57vh"
      >
        <Grid item textAlign="center" marginBottom={8}>
          <Typography variant="h5">
            Inscripción al Primer Concurso de Cacao Mexicano de Origen
          </Typography>
        </Grid>

        <Grid item>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>Descripción del productor/asociación</StepLabel>
            </Step>
            <Step>
              <StepLabel>Descripción de la parcela/finca</StepLabel>
            </Step>
            <Step>
              <StepLabel>Origen genético de la muestra y propagación</StepLabel>
            </Step>
            <Step>
              <StepLabel>Lote donde se originó la muestra</StepLabel>
            </Step>
          </Stepper>
        </Grid>
        <Grid item marginTop={4}>
          {activeStep === 0 ? (
            <Association data={data} onChange={setData} nextStep={nextStep} />
          ) : null}

          {activeStep === 1 ? (
            <DescripcionFinca
              data={data}
              onChange={setData}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          ) : null}

          {activeStep === 2 ? (
            <OrigenGenetico
              data={data}
              onChange={setData}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          ) : null}

          {activeStep === 3 ? (
            <LoteOrigen
              data={data}
              onChange={setData}
              finishStep={finishStep}
              prevStep={prevStep}
              loading={loading}
            />
          ) : null}
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default ConcursoCacaoMexicanoOrigen;
