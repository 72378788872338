import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";

const LoteOrigen = ({ data, onChange, finishStep, prevStep, loading }) => {
  const onSubmit = () => {
    if (
      !data.cantidadLote.trim() ||
      !data.fechaCosecha ||
      !data.tiempoAlmacenado.trim()
    ) {
      toast.error("Por favor llene todos los campos");
      return;
    }

    finishStep();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Cantidad del Lote en Kg*"
          value={data.cantidadLote}
          onChange={(e) => onChange({ ...data, cantidadLote: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          disableFuture
          label="Fecha en que se cosechó"
          format="DD/MM/YYYY"
          value={data.fechaCosecha}
          onChange={(newValue) => {
            onChange({ ...data, fechaCosecha: newValue });
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "standard",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Tiempo almacenada (si es el caso)"
          value={data.tiempoAlmacenado}
          onChange={(e) =>
            onChange({ ...data, tiempoAlmacenado: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button variant="outlined" onClick={prevStep} disabled={loading}>
            Atrás
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={loading}>
            {loading ? "Guardando" : "Finalizar"}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default LoteOrigen;
